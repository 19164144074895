import React from "react";

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <nav
      className={
        (props.transparent
          ? "top-0 absolute z-50 w-full"
          : "relative shadow-lg bg-white shadow-lg") +
        " flex flex-wrap items-center justify-between px-2 py-3 "
      }
    >
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
      <a href = "/">
        <img 
          alt="..."
          src={require("assets/img/brand_logo.png").default}
          className="  max-w-full mx-auto"
          style={{ maxWidth: "180px" }}
        /> 
      </a>
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
        
          <button
            className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <i
              className={
                (props.transparent ? "text-white" : "text-gray-800") +
                "fas fa-bars"
              }
            ></i>
          </button>
        </div>
        <div
          className={
            "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
            (navbarOpen ? " block rounded shadow-lg" : " hidden")
          }
          id="example-navbar-warning"
        >

          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
             
            <li className="flex items-center">
              <a className="px-3 py-2 flex items-center text-m uppercase font-bold leading-snug text-indigo-900 hover:opacity-75" 
              style = {{color: "#3D5A80"}}
              href="#AboutUs">
                About Us
              </a>
            </li>

            <li className="flex items-center">
              <a className="px-3 py-2 flex items-center text-m uppercase font-bold leading-snug text-white hover:opacity-75" href="#Mission"
              style = {{color: "#3D5A80"}}>
                Mission
              </a>
            </li>

            <li className="flex items-center">
              <a className="px-3 py-2 flex items-center text-m uppercase font-bold leading-snug text-white hover:opacity-75" href="#Features"
              style = {{color: "#3D5A80"}}>
                Features
              </a>
            </li>

            

            <li className="flex items-center"> 
              <a className="px-3 py-2 flex items-center text-m uppercase font-bold leading-snug text-white hover:opacity-75" href="https://app.legalglow.com"
              style = {{color: "#3D5A80"}}>
                  Sign In
                </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
