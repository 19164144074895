import React from "react";

import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";
import Testimonials from "components/testimonals"; 
import Features from "components/Features"; 
import Works from "components/Works"; 
import ContactDetails from "components/Contacts"; 

import emailjs from 'emailjs-com';
import information from "../email"; // contains json for emailjs ids 

export default function Landing() { 

  const handleSubmit = (e) => {  
    e.preventDefault(); 
    //'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID'
    console.log(e);
    console.log(e.target);
    const data = new FormData(e.currentTarget);
    console.log(data.get("name"));
    console.log(data.get("email"));
    console.log(data.get("message"));  
  
    emailjs
      .sendForm(
        information.SERVICE_ID,
        information.TemplastForm,
        e.target,
        information.userID
      )
      .then(
        (result) => {
          console.log("result.text");
          console.log(result.text);
          //clearState();
          //e.reset();
          // data.set("name") 
          //document.getElementById("contactform").reset();

          document.getElementById("contactform").reset();
        },
        (error) => {
          console.log(error.text)
        }
      )
 
  }

  return (
    <>
      <Navbar transparent />
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
            style={{
              minHeight: "25vh"
            }}>
          <div className="absolute top-0 w-full h-full bg-center bg-cover"
              style={{
                backgroundImage: "url('https://www.htmlcsscolor.com/preview/gallery/CFFFFF.png')"
              }}>
            <span id="blackOverlay" className="w-full h-full absolute opacity-75"></span>
          </div>
         
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text fill-current"
                points="2560 0 2560 100 0 100"
                style = {{color: "#EE6C4D"}}
              ></polygon>
            </svg>
          </div>
        </div>

        <section id='AboutUs' className="pb-20 -mt-24" style = {{backgroundColor: "#EE6C4D", color: "#3D5A80"}}>
          <Features />
        </section>

        <section id='Mission' className="relative py-20" style = {{backgroundColor: "#cfffff"}}>
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style={{ height: "80px" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-white fill-current" 
              points="2560 0 2560 100 0 100"
              style = {{color: "#cfffff"}}
            ></polygon>
          </svg>
        </div>

         <Works />
        </section>

        <section  id='Features' className="pt-20 relative block pb-48">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center text-center mb-24">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold">
                  Features
                </h2>
                <p className="text-lg leading-relaxed m-4 text-gray-600">
                  What LegalGlow offers to our clients
                </p>
              </div>
            </div>
            <Testimonials /> 
          </div>
        </section>

      
      </main>
      <Footer />
    </>
  );
}
