import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer
      className="relative bg-gray-300 pt-8 pb-6"
      style={{ backgroundColor: "#cfffff" }}
    >
      <div
        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style={{ height: "80px" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text fill-current"
            points="2560 0 2560 100 0 100"
            style={{ color: "#cfffff" }}
          ></polygon>
        </svg>
      </div>
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap">
          {/* <div className="w-full lg:w-6/12 px-4">
            <h4 className="text-3xl font-semibold" style={{ color: "#3D5A80" }}>
              Contact Us
            </h4>
            <div className="mt-6">
              <a href="https://www.linkedin.com/in/legalglow-inc-b8535a231/">
                <button
                  className="bg-white text-blue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3"
                  type="button"
                  href="https://www.linkedin.com/in/legalglow-inc-b8535a231/"
                >
                  <i
                    style={{ display: "flex" }}
                    className="fab fa-linkedin-in"
                  ></i>
                </button>
              </a>
              <a href="https://www.instagram.com/legalglow/">
                <button
                  className="bg-white text-blue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3"
                  type="button"
                  href="https://www.instagram.com/legalglow/"
                >
                  <i
                    style={{ display: "flex" }}
                    className="fab fa-instagram"
                  ></i>
                </button>
              </a>
            </div>
          </div> */}
          {/* <div className="w-full lg:w-6/12 px-4">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-4/12 px-4 ml-auto">
              <ul className="list-unstyled">
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm">
                    Address 
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm">
                    5307 Victoria Drive #244, Vancouver, BC, Canada
                    </a>
                  </li>
                  
                </ul>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                
                <ul className="list-unstyled">
                  
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm">
                    Email
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                    > info@legalglow.com
                    </a>
                  </li>

                </ul>

              </div>
            </div>
          </div> */}
        </div>
        <hr className="my-6 border-gray-400" />
        {/*
          <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-gray-600 font-semibold py-1">
              Copyright © {new Date().getFullYear()}{" "}Tailwind Starter Kit by{" "}
              <a
                href="https://www.creative-tim.com"
                className="text-gray-600 hover:text-gray-900"
              >
                Creative Tim
              </a>.
            </div>
          </div>
        </div> 
          */}
      </div>
    </footer>
  );
}
