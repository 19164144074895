import React from "react";

const data =  [
        {
          "img": "assets/img/testimonials/04.jpg",
          "text": "\"Great application, made eating out really easy and informative.\"",
          "name": "Charlie Smith"
        },
        {
          "img": "assets/img/testimonials/05.jpg",
          "text": "\"I went on a trip, and this app made it easy to find the best places to eat and what to order.\"",
          "name": "Brian Clark"
        },
        {
          "img": "assets/img/testimonials/06.jpg",
          "text": "\" I love leaving reviews for restaurants and seeing what other peoples are eating, it also makes it a lot easier to figure out the specialities of different restaurants. \"",
          "name": "Jane Olive"
        }
      ];

export default function Features() {
  return (
    <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
            </div>


            <div className="flex flex-wrap items-center mt-32">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <h2 className="text-4xl mb-2 font-bold leading-normal">
                  About LegalGlow
                </h2>
                <p className="text-lg  leading-relaxed mt-4 mb-4 text-gray-700" style = {{color: "#82FBFF"}}>  
                Our platform allows clients to find the right lawyer for their case through communicating with multiple lawyers at any time from anywhere. 
                </p>
                <p className="text-lg leading-relaxed mt-0 mb-4 text-gray-700" style = {{color: "#82FBFF"}}>
                    Clients tend to spend hours and hours searching for a list of lawyers that they can call or email, only to be told that the lawyer is too busy to talk or that they will get back to them if they leave a message.                 </p>
                <p className="text-lg leading-relaxed mt-0 mb-4 text-gray-700" style = {{color: "#82FBFF"}}>
                   The connection between lawyers and clients should be less complicated! At any time that you are in need of a lawyer, LegalGlow is here to help. No more barriers or intermediaters between lawyers and clients!
                </p>
                
              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-blue-600">
                <video controls>
                            <source src={require("assets/vid/Video2.mp4").default} 
                              type="video/mp4"></source>
                            Sorry, your browser doesn't support videos.
                        </video>
                 
                </div>
              </div>

            </div>
          </div>
    
  );
}

/*
 <div className="flex flex-wrap">
    <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
      <div className="px-6">
        <img
          alt="..."
          src={require("assets/img/team-1-800x800.jpg").default}
          className="shadow-lg rounded-full max-w-full mx-auto"
          style={{ maxWidth: "120px" }}
        />
        <div className="pt-6 text-center">
          <h5 className="text-xl font-bold">
            Ryan Tompson
          </h5>
          <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
            Web Developer
          </p>
          <div className="mt-6">
            <button
              className="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-twitter"></i>
            </button>
            <button
              className="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-facebook-f"></i>
            </button>
            <button
              className="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-dribbble"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
      <div className="px-6">
        <img
          alt="..."
          src={require("assets/img/team-2-800x800.jpg").default}
          className="shadow-lg rounded-full max-w-full mx-auto"
          style={{ maxWidth: "120px" }}
        />
        <div className="pt-6 text-center">
          <h5 className="text-xl font-bold">
            Romina Hadid
          </h5>
          <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
            Marketing Specialist
          </p>
          <div className="mt-6">
            <button
              className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-google"></i>
            </button>
            <button
              className="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-facebook-f"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
      <div className="px-6">
        <img
          alt="..."
          src={require("assets/img/team-3-800x800.jpg").default}
          className="shadow-lg rounded-full max-w-full mx-auto"
          style={{ maxWidth: "120px" }}
        />
        <div className="pt-6 text-center">
          <h5 className="text-xl font-bold">
            Alexa Smith
          </h5>
          <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
            UI/UX Designer
          </p>
          <div className="mt-6">
            <button
              className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-google"></i>
            </button>
            <button
              className="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-twitter"></i>
            </button>
            <button
              className="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-instagram"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
      <div className="px-6">
        <img
          alt="..."
          src={require("assets/img/team-4-470x470.png").default}
          className="shadow-lg rounded-full max-w-full mx-auto"
          style={{ maxWidth: "120px" }}
        />
        <div className="pt-6 text-center">
          <h5 className="text-xl font-bold">
            Jenna Kardi
          </h5>
          <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
            Founder and CEO
          </p>
          <div className="mt-6">
            <button
              className="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-dribbble"></i>
            </button>
            <button
              className="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-google"></i>
            </button>
            <button
              className="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-twitter"></i>
            </button>
            <button
              className="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
              type="button"
            >
              <i className="fab fa-instagram"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
*/